import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"
import TextBlock from "../components/textBlock"
import ValuesBlock from "../components/valuesBlock"
import ImageBlock from "../components/imageBlock"
import TeamBlock from "../components/teamBlock"
import HeroBlock from "../components/heroBlock"
import GalleryBlock from "../components/galleryBlock"
import NumbersBlock from "../components/numbersBlock"

const Page = ({ data: { page }, pageContext, location }) => {
  const locale = pageContext.locale
  const allSlugLocales = [
    { locale: "it", value: "" },
    { locale: "en", value: "" },
  ]
  // console.log(page)
  return (
    <Layout lang={locale} title={page.title} location={location} allSlugLocales={allSlugLocales}>
      <Content>
        {page.content.map((section, index) => (
          <section key={section.id}>
            {section.model.apiKey === "hero_block" && (
              <HeroBlock title={section.title} text={section.text} page={"home"} lang={locale} />
            )}
            {section.model.apiKey === "numbers_block" && (
              <NumbersBlock numbers={section.number} images={section.images} />
            )}
            {section.model.apiKey === "text_block" && (
              <TextBlock
                title={section.title}
                subtitle={section.subtitle}
                text={section.text}
                border={section.border}
              />
            )}
            {section.model.apiKey === "gallery_block" && (
              <GalleryBlock
                title={section.title}
                text={section.text}
                gallery={section.gallery}
                parentIndex={index}
                link={section.link}
              />
            )}
            {section.model.apiKey === "values_block" && (
              <ValuesBlock values={section.values} />
            )}
            {section.model.apiKey === "image_block" && (
              <ImageBlock image={section.image} fullWidth={section.fullWidth} />
            )}
            {section.model.apiKey === "team_block" && (
              <TeamBlock people={section.people} />
            )}
          </section>
        ))}
      </Content>
    </Layout>
  )
}

const Content = styled.div``

export default Page

export const query = graphql`
  query HomePageQuery($locale: String!) {
    page: datoCmsHomePage(
      originalId: { eq: "9528775" }
      locale: { eq: $locale }
    ) {
      title
      id
      locale
      content {
        ... on DatoCmsGalleryBlock {
          title
          text
          gallery {
            fluid(maxWidth: 1440, imgixParams: { fm: "jpg" }) {
              ...GatsbyDatoCmsFluid
            }
            originalId
          }
          link {
            id
            name
            page {
              slug
            }
          }
          id
          model {
            apiKey
          }
        }
        ... on DatoCmsHeroBlock {
          title
          text
          id
          model {
            apiKey
          }
        }
      }
    }
  }
`
